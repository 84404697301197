













































import { Component, Mixins, Prop } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import { Tab } from "@/interfaces";
import CompanyFormCard from "@/components/company-form/CompanyFormCard.vue";
import DialogMixin from "@/mixins/DialogMixin";
import Loader from "@/components/common/Loader.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import ChangeCompanyIsDisabledCell from "@/components/contact/ChangeCompanyIsDisabledCell.vue";
import CompanyService from "@/services/CompanyService";
import Company from "@/entity/Company";

@Component({
  components: {
    Tabs,
    CompanyFormCard,
    Loader,
    ActionButtonWithTooltip,
    ChangeCompanyIsDisabledCell,
  },
})
export default class CompanyLayout extends Mixins(DialogMixin) {
  @Prop({ type: Number, required: true }) companyId!: number;

  company: Company | null = null;
  isCompanyFetching = false;

  get tabs(): Tab[] {
    return [
      {
        routeName: "companyDetail",
        name: this.$tc("detail", 1),
      },
      {
        routeName: "companyProspects",
        name: this.$tc("prospects"),
      },
      {
        routeName: "companySearchProfiles",
        name: this.$tc("searchProfiles"),
      },
      {
        routeName: "companyHistory",
        name: this.$tc("history"),
      },
    ];
  }

  async fetchCompany(): Promise<void> {
    try {
      this.isCompanyFetching = true;
      this.company = await CompanyService.findOne(this.companyId);
      this.$replaceBreadcrumb({
        key: ":companyId",
        value: this.company?.name,
      });
    } catch (e) {
      await this.$router.push({ name: "404" });
    } finally {
      this.isCompanyFetching = false;
    }
  }

  onFormSuccess(): void {
    this.closeDialog();
    this.fetchCompany();
  }

  created() {
    this.fetchCompany();
  }
}
