import Axios from "axios";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import filterQueryParams from "@/services/filterQueryParams";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import { ProjectType, Prospect } from "@/entity/Project";
import Company, { CreateCompany, getCompanyFullName } from "@/entity/Company";

export default class CompanyService {
  public static async find(
    query: TableQuery
  ): Promise<ListResponseEntity<Company>> {
    const response = await Axios.get("/api/v2/company", {
      params: {
        ...filterQueryParams(query),
      },
    });

    return {
      ...response.data,
      content: response.data.content.map((company: Company) => ({
        ...company,
        fullName: getCompanyFullName(company),
      })),
    };
  }

  public static async findOne(companyId: number | string): Promise<Company> {
    const response = await Axios.get(`/api/v2/company/${companyId}`);
    return {
      ...response.data,
      fullName: getCompanyFullName(response.data),
    };
  }

  public static async createCompany(company: CreateCompany): Promise<Company> {
    const payload = {
      ...company,
    };
    const response = await Axios.post("/api/v2/company", payload);
    return response.data;
  }

  public static async updateCompany(
    companyId: number | string,
    company: CreateCompany
  ): Promise<Company> {
    const response = await Axios.put(`/api/v2/company/${companyId}`, {
      ...company,
    });
    return response.data;
  }

  public static async findUnusedCompanies(
    projectType: ProjectType
  ): Promise<Company[]> {
    const response = await Axios.get(`/api/v2/company/unused/${projectType}`);
    return response.data;
  }

  public static async getProspects(
    companyId: number,
    itemsPerPage = ALL_ITEMS_PER_PAGE
  ): Promise<ListResponseEntity<Prospect>> {
    const response = await Axios.get(`/api/v2/company/${companyId}/prospects`, {
      params: {
        itemsPerPage,
      },
    });
    return response.data;
  }

  public static async setIsDisabled(
    companyId: number,
    isDisabled: boolean
  ): Promise<void> {
    const response = await Axios.put(
      `/api/v2/company/${companyId}/disable`,
      {},
      {
        params: {
          enable: !isDisabled,
        },
      }
    );
    return response.data;
  }
}
