














import { Vue, Component, Prop } from "vue-property-decorator";
import { Tab } from "@/interfaces";

@Component
export default class Tabs extends Vue {
  @Prop({ required: true }) tabs!: Tab[];

  get visibleTabs() {
    return this.tabs.filter((tab) => tab.hidden !== true);
  }
}
