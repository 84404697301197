











import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import ContactsService from "@/services/ContactsService";
import Contact from "@/entity/Contact";
import { Getter } from "vuex-class";
import CompanyService from "@/services/CompanyService";

@Component({
  components: {
    ActionButtonWithTooltip,
  },
})
export default class ChangeCompanyIsDisabledCell extends Vue {
  @Prop({ required: true, type: Object }) contact!: Contact;

  @Getter("isAdmin", { namespace: "auth" }) isAdmin!: boolean;
  @Getter("isProjectsAdmin", { namespace: "auth" }) isProjectsAdmin!: boolean;

  isChangingIsDisabled = false;

  get showContactDisableButton() {
    return this.isAdmin || this.isProjectsAdmin;
  }

  get changeContactIsDisableLabel(): string {
    if (this.contact?.isDisabled) {
      return this.$t("enable").toString();
    }

    return this.$t("disable").toString();
  }

  get changeContactIsDisabledIcon(): string {
    if (this.contact?.isDisabled) {
      return "mdi-account-check";
    }

    return "mdi-account-remove";
  }

  async changeContactIsDisabled(): Promise<void> {
    const value = !this.contact?.isDisabled;

    const message = this.$t("confirmations.deactivateCompany");

    if (value) {
      const response = await this.$confirm(message.toString());
      if (!response) return;
    }

    try {
      this.isChangingIsDisabled = true;
      await CompanyService.setIsDisabled(this.contact.id as number, value);
      this.onContactIsDisabledChange(value);
    } finally {
      this.isChangingIsDisabled = false;
    }
  }

  @Emit("contact-is-disabled-changed")
  onContactIsDisabledChange(value: boolean) {
    return value;
  }
}
