import Axios from "axios";
import { FilterBy, TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import filterQueryParams from "@/services/filterQueryParams";
import Contact, {
  CreateContact,
  getContactFullName,
  CsvDataContact,
} from "@/entity/Contact";

export default class ContactsService {
  public static async find(
    query: TableQuery
  ): Promise<ListResponseEntity<Contact>> {
    const response = await Axios.get("/api/v2/contacts2", {
      params: {
        ...filterQueryParams(query),
      },
    });

    return {
      ...response.data,
      content: response.data.content.map((contact: Contact) => ({
        ...contact,
        fullName: getContactFullName(contact),
      })),
    };
  }

  public static async findWithCompanyAndBuyer(): Promise<
    ListResponseEntity<Contact>
  > {
    const response = await Axios.get(
      "/api/v2/contacts2/listWithCompanyAndBuyer"
    );

    return {
      ...response.data,
      content: response.data.map((contact: Contact) => ({
        ...contact,
        fullName: getContactFullName(contact),
      })),
    };
  }

  public static async findOne(contactId: number | string): Promise<Contact> {
    const response = await Axios.get(`/api/v2/contacts2/${contactId}`);
    return {
      ...response.data,
      fullName: getContactFullName(response.data),
    };
  }

  public static async findAll(): Promise<Contact[]> {
    const response = await Axios.get(`/api/v2/contacts2/all`);

    const res = response.data.map((contact: Contact) => ({
      ...contact,
      fullName: getContactFullName(contact),
    }));
    return res;
  }

  public static async createContact(
    contact: CreateContact,
    companyId: any
  ): Promise<Contact> {
    let url = `/api/v2/contacts2`;
    if (companyId) {
      url += `/${companyId}`;
    }
    const payload = {
      ...contact,
    };
    const response = await Axios.post(url, payload);
    return response.data;
  }

  public static async updateContact(
    contactId: number,
    contact: CreateContact,
    companyId: any
  ): Promise<Contact> {
    let url = `/api/v2/contacts2/${contactId}`;
    if (companyId) {
      url += `/${companyId}`;
    }
    const response = await Axios.put(url, {
      ...contact,
      isBlockedRaw: contact.isBlocked ? 1 : 0,
    });
    return response.data;
  }

  public static async sendNda(
    contactId: number | string,
    email?: string
  ): Promise<void> {
    const response = await Axios.post(
      `/api/v2/contacts2/${contactId}/sendNDA`,
      { email }
    );
    return response.data;
  }

  public static async approveNda(contactId: number): Promise<void> {
    const response = await Axios.post(
      `/api/v2/contacts2/${contactId}/approveNDA`
    );
    return response.data;
  }

  public static async rejectNda(
    contactId: number,
    note?: string
  ): Promise<void> {
    const response = await Axios.post(
      `/api/v2/contacts2/${contactId}/rejectNDA`,
      {
        note,
      }
    );
    return response.data;
  }

  public static async setIsDisabled(
    contactId: number,
    isDisabled: boolean
  ): Promise<void> {
    const response = await Axios.put(
      `/api/v2/contacts2/${contactId}/disable`,
      {},
      {
        params: {
          enable: !isDisabled,
        },
      }
    );
    return response.data;
  }

  public static async getDataForExport(
    filter: FilterBy
  ): Promise<CsvDataContact[]> {
    const response = await Axios.get("/api/v2/contacts2/export", {
      params: filter,
    });

    return response.data;
  }
}
